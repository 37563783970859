import React, { FC, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTracking } from 'react-tracking'

import { ConnectionType } from '../../connectionType'
import { ProviderStatusType } from '../../providerStatusType'
import ProviderDialog from '../ProviderDialog/ProviderDialog'
import DisconnectDialog from '../DisconnectDialog/DisconnectDialog'
import { selectProviderStatus } from '../../selectors'
import ResultDialog from '../ResultDialog/ResultDialog'
import { providerLabelTemplate } from '../../../core/utils/providerLabelTemplate'
import * as Sentry from '@sentry/react'
import './ProviderItem.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../../core/utils/css-selectors'
import { getRedirectURLForOAUTH } from '../../actions'
import MDEditor from '@uiw/react-md-editor'

interface ProviderItemProps {
  provider: ProviderAccount
}

const ProviderItem: FC<ProviderItemProps> = ({ provider }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { trackEvent } = useTracking()
  const providerStatus: ProviderStatus | undefined = useSelector(selectProviderStatus(provider.ProviderID))
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState<boolean>(false)
  const [isResultOpen, setIsResultOpen] = useState<boolean>(false)
  const [isSpinner, setIsSpinner] = useState<boolean>(true)
  const [isDisconnectOpen, setIsDisconnectOpen] = useState<boolean>(false)
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)

  const isEditMod = (providerStatus?: ProviderStatus): boolean => {
    if (providerStatus !== undefined) {
      if (providerStatus.status === ProviderStatusType.Validating) {
        return true
      }
    }
    return false
  }

  const isFailedMod = (providerStatus?: ProviderStatus): boolean => {
    if (providerStatus !== undefined) {
      if (providerStatus.status === ProviderStatusType.FailedToValidate) {
        return true
      }
    }
    return false
  }

  const isConnectMod = (providerStatus?: ProviderStatus): boolean => {
    if (providerStatus !== undefined) {
      if (
        providerStatus.status === ProviderStatusType.Connected ||
        providerStatus.status === ProviderStatusType.RequireDisconnect
      ) {
        return true
      }
    }
    return false
  }

  const isRequireToDisconnect = (providerStatus?: ProviderStatus): boolean => {
    if (providerStatus !== undefined) {
      if (providerStatus.status === ProviderStatusType.RequireDisconnect) {
        return true
      }
    }
    return false
  }

  const handleClickSwitch = (): void => {
    if (provider.DisableUserControl) {
      return
    }
    if (
      provider.connectionType === ConnectionType.selfReport ||
      provider.connectionType === ConnectionType.directAPI ||
      provider.connectionType === ConnectionType.directOauth
    ) {
      if (
        providerStatus?.status === ProviderStatusType.Connected ||
        providerStatus?.status === ProviderStatusType.RequireDisconnect
      ) {
        setIsDisconnectOpen(true)
        return
      }
      trackEvent({
        action: 'Start App Connection',
        page: 'Connect Apps',
        payload: {
          origin: 'connect screen',
          appID: provider.ProviderID
        }
      })
      if (provider.connectionType === ConnectionType.directOauth) {
        if (provider.IsPKCEFlow) {
          showSpinner()
          dispatch(getRedirectURLForOAUTH(provider.ProviderID))
            .then(() => setIsConnectDialogOpen(true))
            .catch((error: Error) => Sentry.captureException(error, {
              tags: {
                method: 'handleClickSwitch'
              }
            }))
            .finally(() => closeSpinner())
          return
        }
      }
      setIsConnectDialogOpen(true)
    }
  }

  const showMessage = (providerStatus?: ProviderStatus): void => {
    if (providerStatus != null) {
      setIsSpinner(false)
      if (providerStatus.status === ProviderStatusType.FailedToValidate) {
        setIsResultOpen(false)
      }
    } else {
      setIsResultOpen(false)
      setIsSpinner(true)
      setIsConnectDialogOpen(true)
    }
  }

  const showSpinner = (): void => {
    setIsConnectDialogOpen(false)
    setIsResultOpen(true)
    setIsSpinner(true)
  }

  const closeSpinner = (): void => {
    setIsSpinner(false)
    setIsResultOpen(false)
  }

  const handleResultClose = (flag: boolean): void => {
    setIsSpinner(true)
    setIsResultOpen(flag)
  }

  return (
    <div className='provider' onClick={handleClickSwitch}>

      {isFailedMod(providerStatus) &&
        <div className='provider__failed'>
          <span>{t`ProviderFailedToVerifyMsg`}</span>
        </div>}

      {!isFailedMod(providerStatus) &&
      isEditMod(providerStatus) &&
      !isRequireToDisconnect(providerStatus) && (
        <div className='provider__header'>
          <span>{t`ProviderVerifyingMsg`}</span>
        </div>
      )}
      {isRequireToDisconnect(providerStatus) && (
        <div className='provider__header require_disconnect_header'>
          <span>{t`ProviderRequireToDisconnectMsg`}</span>
        </div>
      )}
      <div className='provider__description'>
        <div className='provider__description__logo'>
          <div
            style={{ backgroundImage: `url(${provider.logoImageUrl})` }}
            className='provider__description__logo__img'
            role='img'
            aria-label='A image of the provider'
          />
        </div>

        <div className='provider__description__text'>
          {provider.translations?.connectTitle != null &&
            <div className='provider__description__text__title'>{providerLabelTemplate(provider.Label, provider.translations.connectTitle)}</div>}

          <div className='provider__description__text__subtitle'>
            <MDEditor.Markdown
              source={provider.connectDescription}
              style={{ whiteSpace: 'pre-wrap' }}
            />
          </div>
        </div>
      </div>
      <div className='provider__connection'>
        <Switch
          className={`switcher--default ${isEditMod(providerStatus) ? 'switcher--validate' : ''}`}
          checked={isConnectMod(providerStatus)}
          disabled={provider.DisableUserControl}
          inputProps={{
            'aria-label': 'Provider connection status'
          }}
        />
      </div>
      {(isFailedMod(providerStatus) || isEditMod(providerStatus)) &&
        <div className='provider__link default-link'>
          {t`ProviderEditInfo`}
        </div>}
      <ProviderDialog
        isConnectDialogOpen={isConnectDialogOpen}
        setIsConnectDialogOpen={setIsConnectDialogOpen}
        handleSubmitStart={showSpinner}
        handleSubmit={showMessage}
        provider={provider}
        identifier={providerStatus?.identifier != null ? providerStatus.identifier : ''}
        isFromProviderItem={matchesMd}
      />
      <DisconnectDialog
        isOpen={isDisconnectOpen}
        setIsDisconnectOpen={setIsDisconnectOpen}
        handleSubmitStart={showSpinner}
        handleSubmitFinish={closeSpinner}
        providerStatus={providerStatus}
        label={provider.translations?.fullName != null ? providerLabelTemplate(provider.Label, provider.translations.fullName) : ''}
      />
      <ResultDialog
        isOpen={isResultOpen}
        setIsOpen={handleResultClose}
        isSpinner={isSpinner}
        providerStatus={providerStatus}
        label={provider.translations?.fullName != null ? providerLabelTemplate(provider.Label, provider.translations.fullName) : ''}
      />
    </div>
  )
}

export default ProviderItem
