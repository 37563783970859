import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@react-hook/media-query'
import {
  ButtonBase,
  Dialog,
  IconButton,
  Slide,
  SlideProps
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { IdList } from '../../core/utils/id-list'
import { breakpoints } from '../../core/utils/css-selectors'
import { RedemptionCenterCondition } from '../../offer/redemptionCenterCondition'
import BackIco from '../customIcons/BackIco'
import CloseBtn from './CloseBtn/CloseBtn'
import { selectCurrentTutorialState } from '../../tutorial/selectors'
import './BaseDrawer.scss'

const TransitionUp = React.forwardRef<unknown, SlideProps>((props, ref) => {
  return <Slide direction='up' ref={ref} {...props} />
})

const TransitionRight = React.forwardRef<unknown, SlideProps>((props, ref) => {
  return <Slide direction='right' ref={ref} {...props} />
})

interface BaseDrawerProp {
  children: React.ReactNode
  title?: string
  isOpen: boolean
  isButtonAvailable?: boolean
  button?: React.ReactNode
  isCentered?: boolean
  centerCondition?: string
  onClose?: () => void
  onBackdrop?: () => void
  onBack?: () => void
  noPadding?: boolean
  hasHeader?: boolean
  customClassName?: string
  fullWidth?: boolean
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  isFromDesktopTransactionHistory?: boolean
  dialogClassName?: string
  customMargin?: string
  isFromUpdatedUI?: boolean
  isFromUpdatedDesktopUI?: boolean
  customDialogClass?: string
  isFromRedemptionError?: boolean
}
const DEFAULT_TIMEOUT = 150

const BaseDrawer: FC<BaseDrawerProp> = ({
  isOpen,
  isButtonAvailable = false,
  button,
  onClose,
  children,
  title,
  isCentered = false,
  onBack,
  onBackdrop,
  centerCondition = '',
  noPadding = false,
  hasHeader = true,
  customClassName,
  fullWidth = false,
  maxWidth,
  isFromDesktopTransactionHistory = false,
  dialogClassName = '',
  customMargin = '',
  isFromUpdatedUI = false,
  isFromUpdatedDesktopUI = false,
  customDialogClass = '',
  isFromRedemptionError = false
}) => {
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const { t } = useTranslation()
  const [isWrapperEmpty, setIsWrapperEmpty] = useState<boolean>(false)
  const currentTutorialState = useSelector(selectCurrentTutorialState)
  const hasTitle = title != null && title !== ''

  const handleCloseMenu = (): void => {
    if (onClose !== undefined) {
      onClose()
    }
  }

  const handleBackMenu = (): void => {
    if (onBack !== undefined) {
      onBack()
    }
  }

  const handleBackdropClick = (): void => {
    if (onBackdrop !== undefined) {
      onBackdrop()
    }
  }

  const calcIsWrapperEmpty = (): boolean => {
    if (
      !matchesMd &&
      centerCondition === RedemptionCenterCondition.both &&
      isCentered
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    setIsWrapperEmpty(calcIsWrapperEmpty())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchesMd, centerCondition, isCentered])

  return (
    <Dialog
      classes={
        !matchesMd && isCentered
          ? {
            container:
                centerCondition === RedemptionCenterCondition.both
                  ? 'base-drawer-center'
                  : '',
            paper: 'base-drawer-modal'
          }
          : {
            container:
                centerCondition === RedemptionCenterCondition.both || isFromUpdatedDesktopUI
                  ? 'base-drawer-center'
                  : 'base-drawer',
            paper: `${
                !isFromUpdatedDesktopUI
                  ? 'base-drawer-modal__side'
                  : 'base-drawer-centered'
              } ${isFromUpdatedUI ? 'drawer-updated' : ''} ${customDialogClass}`
          }
      }
      open={isOpen}
      onBackdropClick={handleBackdropClick}
      onClose={handleCloseMenu}
      onClick={(e) => {
        e.stopPropagation()
      }}
      TransitionComponent={matchesMd ? TransitionRight : TransitionUp}
      TransitionProps={{
        timeout: currentTutorialState.tutorialOn ? 0 : DEFAULT_TIMEOUT
      }}
      BackdropProps={{
        style: {
          opacity:
            currentTutorialState.currentStepTitle === 'Campaign Details' ? 0 : 1
        }
      }}
      style={{
        zIndex: currentTutorialState.currentStepTitle === 'More Tab' ? 10 : 100,
        opacity: !currentTutorialState.tutorialOn
          ? 1
          : !currentTutorialState.campaignDrawerOpenForTutorial &&
            !currentTutorialState.moreTabDrawerOpenForTutorial
            ? 0
            : 1
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      className={dialogClassName}
      aria-labelledby='base-drawer-dialog'
      aria-describedby='base-drawer-dialog-description'
    >
      {!isWrapperEmpty && hasHeader && (
        <div
          className={`base-drawer__title__wrapper ${
            isFromUpdatedDesktopUI && !isFromRedemptionError ? 'p-0' : ''
          } ${isFromDesktopTransactionHistory ? 'mb-2' : ''}`}
          id='base-drawer-dialog'
        >
          {matchesMd &&
          centerCondition !== '' &&
          centerCondition !== RedemptionCenterCondition.both
            ? (
              <IconButton
                id={IdList.navModalClose}
                onClick={handleCloseMenu}
                className='base-drawer-close'
                style={{ marginTop: customMargin }}
                aria-label='close-dialog'
              >
                <BackIco />
              </IconButton>
            )
            : (
              (!isCentered || isFromUpdatedDesktopUI) &&
                (isFromDesktopTransactionHistory
                  ? (
                    <div style={{ marginTop: 45, marginBottom: '-2em' }}>
                      {onClose !== undefined && (
                        <div style={{ marginLeft: '-1em' }}>
                          <CloseBtn handleCloseMenu={handleCloseMenu} />
                        </div>
                      )}
                      <p className={`f-size-18 f-weight-bold base-drawer__title ${hasTitle ? '' : 'hide'}`}>{title}</p>
                    </div>
                  ) : (
                    <>
                      {onClose !== undefined && (
                        <div style={{ margin: isFromUpdatedDesktopUI ? customMargin : '', marginTop: customMargin }}>
                          <CloseBtn handleCloseMenu={handleCloseMenu} isFromUpdatedDesktopUI />
                        </div>
                      )}

                      {onBack !== undefined && (
                        <ButtonBase
                          id={IdList.navModalClose}
                          className='base-drawer-close'
                          onClick={handleBackMenu}
                          style={{ marginTop: customMargin }}
                          aria-label='close-dialog'
                        >
                          {matchesMd && (
                            <KeyboardArrowLeftIcon className='base-drawer__title__back__ico' />
                          )}
                          {t`Back`}
                        </ButtonBase>
                      )}
                      <span className={`f-size-18 f-weight-bold base-drawer__title ${hasTitle ? '' : 'hide'}`}>{title}</span>
                      {isButtonAvailable ? (
                        <div className='drawer-button'>{button}</div>
                      ) : null}
                    </>
                  ))
            )}
        </div>
      )}
      <div
        className={`${
          noPadding
            ? 'base-drawer__content__no-padding'
            : 'base-drawer__content'
        } ${customClassName ?? ''} ${isFromUpdatedUI || isFromUpdatedDesktopUI ? 'h-100 p-0' : ''}`}
      >
        {children}
      </div>
    </Dialog>
  )
}

export default BaseDrawer
