import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PinInput from 'react-pin-input'
import moment, { Duration } from 'moment'
import { useMediaQuery } from '@react-hook/media-query'
import { useDispatch } from 'react-redux'
import { useTracking } from 'react-tracking'
import { Link } from '@mui/material'

import BaseDrawer from '../../../../ui/BaseDrawer/BaseDrawer'
import { userService } from '../../../../user/service'
import { getUserInfo } from '../../../../user/actions'
import NumberFormat from 'react-number-format'
import { breakpoints } from '../../../../core/utils/css-selectors'

import './PhoneVerificationWithCode.scss'
import { AnalyticsCategory } from '../../../../core/analytics/analyticsCategory'
import { getErrorMessage } from '../../../../utils'
import { ENTER_VALID_CODE } from '../constants'

interface PhoneVerificationWithCodeProps {
  verificationCode: VerificationCode | undefined
  isPhoneVerificationWithCodeOpen: boolean
  handleClosePhoneVerificationWithCode: () => void
  handleBack: () => void
  resendCode: () => void | Promise<void>
  mobilePhone: string
  showMessage: (error?: string) => void
  showSpinner: () => void
  isMigrationRequest: boolean
}

const PhoneVerificationWithCode: FC<PhoneVerificationWithCodeProps> = ({
  showSpinner,
  showMessage,
  verificationCode,
  isPhoneVerificationWithCodeOpen,
  handleClosePhoneVerificationWithCode,
  resendCode,
  mobilePhone,
  handleBack,
  isMigrationRequest
}) => {
  const { t } = useTranslation()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const dispatch = useDispatch()
  const { trackEvent } = useTracking()
  const [timeDiff, setTimeDiff] = useState<Duration>()
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (verificationCode !== undefined) {
      const timeDiff: Duration = moment.duration(moment.utc(verificationCode.coolDownTimestampUTC).diff(moment.utc()))
      setTimeDiff(timeDiff)

      const interval = setInterval(() => {
        setTimeDiff((state) => {
          if (state !== undefined) {
            const newDiff = state.clone().subtract(1, 'seconds')

            if (newDiff.asSeconds() > 0) {
              return newDiff
            }
          }
          return undefined
        })
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [verificationCode])

  const handlePhoneVerificationCode = async (code: string): Promise<void> => {
    showSpinner()
    return await userService
      .validateCode(code, isMigrationRequest)
      .then(() => {
        trackEvent({
          page: AnalyticsCategory.PhoneVerification,
          action: 'Phone verified'
        })
      })
      .then(() => {
        showMessage()
        setErrorMessage('')
      })
      .then(() => dispatch(getUserInfo()))
      .catch((error) => {
        const errorMsg = getErrorMessage(error, t)
        const displayMsg = errorMsg === ENTER_VALID_CODE ? t`EnterValidCode` : errorMsg
        showMessage(displayMsg)
        setErrorMessage(displayMsg)
      })
  }

  const handleResendCode = async (): Promise<void> => {
    trackEvent({
      page: AnalyticsCategory.PhoneVerification,
      action: 'Resend Verification Code'
    })
    return await resendCode()
  }

  return (
    <BaseDrawer
      isOpen={isPhoneVerificationWithCodeOpen}
      onBackdrop={handleClosePhoneVerificationWithCode}
      onBack={handleBack}
      customMargin={matchesMd ? '45px' : ''}
    >

      <div className='phone-verification-code'>
        {matchesMd &&
          <div className='phone-verification-code__content__title'>
            {t`VerifyAccount`}
          </div>}
        <div className='phone-verification-code__content__description'>
          <div className='phone-verification-code__content__description__title'>{t`CodeVerify`}</div>
          <div className='phone-verification-code__content__description__subtitle'>
            <span>{t`codeVerifyNotice`}</span>
            <span>
              <NumberFormat
                value={mobilePhone}
                displayType='text'
                format='###-###-####'
              />
            </span>
          </div>
        </div>

        <div className='phone-verification-code__form-control-code'>
          <PinInput
            type='numeric'
            inputMode='number'
            length={4}
            focus
            onComplete={async (code) => await handlePhoneVerificationCode(code)}
          />

          {errorMessage !== '' &&
            <div className='phone-verification-code__form-control-code__err-msg'>
              {errorMessage}
            </div>}

          {timeDiff !== undefined
            ? (
              <div className='phone-verification-code__form-control-code__resend-code--disabled'>
                {t`resendCodeIn`} {moment.utc(timeDiff.asMilliseconds()).format('m:ss')}
              </div>)
            : (
              <Link
                component='button'
                className='phone-verification-code__form-control-code__resend-code'
                onClick={handleResendCode}
              >
                {t`resendCode`}
              </Link>)}
        </div>
      </div>
    </BaseDrawer>
  )
}

export default PhoneVerificationWithCode
