import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import track, { useTracking } from 'react-tracking'
import { ButtonBase, Snackbar } from '@mui/material'
import { useSelector } from 'react-redux'

import { selectConfig } from '../../config/selectors'
import { IdList } from '../../core/utils/id-list'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
import history from '../../core/history'
import error from '../../../assets/images/error.png'
import './Error.scss'
import { AppErrorType } from '../../config/appErrorType'
import { RoutePath } from '../../core/routes/route-path'

const Error: FC = () => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const config: ConfigState = useSelector(selectConfig)
  const textCopyRef = useRef<any>()
  const [isCopiedOpen, setIsCopiedOpen] = useState(false)

  useEffect(() => {
    if (config.AppErrorType !== AppErrorType.none) {
      trackEvent({
        page: AnalyticsCategory.Error,
        action: 'General Error Page View',
        payload: {
          AppErrorType: config.AppErrorType
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.AppErrorType])

  useEffect((): void => {
    if (config.AppErrorType === AppErrorType.none) {
      history.push(RoutePath.Home)
    }
  }, [config])

  const reportIssue = (): void => {
    trackEvent({
      page: AnalyticsCategory.Error,
      action: 'Press on Report Issue',
      payload: {
        origin: 'general error',
        AppErrorType: config.AppErrorType
      }
    })
    textCopyRef.current?.select()
    document.execCommand('copy')
    window.getSelection()?.removeAllRanges()
    setIsCopiedOpen(true)
  }

  const getAppErrorMessagesByType = (): any => {
    switch (config.AppErrorType) {
      case AppErrorType.loginError: {
        return {
          title: t`appLoginErrorTitle`,
          description: t`appLoginErrorDescription`
        }
      }

      case AppErrorType.networkError: {
        return {
          title: t`appNetworkErrorTitle`,
          description: t`appNetworkErrorDescription`
        }
      }

      case AppErrorType.maintenance: {
        return {
          title: t`maintenanceErrorTitle`,
          description: t`maintenanceErrorDescription`
        }
      }

      default: {
        return {
          title: t`generalErrorTitle`,
          description: t`generalErrorDescription`
        }
      }
    }
  }
  const pageTexts = getAppErrorMessagesByType()

  return (
    <div className='error'>
      <div className='error__image'>
        <img src={error} alt='representing an error' />
      </div>
      <span className='error__text error__text__title'>{pageTexts.title}</span>
      <span className='error__text error__text__description'>
        {pageTexts.description}
      </span>

      <ButtonBase
        id={IdList.reportIssue}
        className='link-button'
        onClick={reportIssue}
      >
        <span
          className='callus_txt'
          style={{ textTransform: 'none' }}
        >
          {t`CallUsAt`}
        </span>
        <input
          ref={textCopyRef}
          className='phone_txt'
          type='text'
          readOnly
          value={config.SupportPhoneNumber}
          style={{
            border: 'none',
            padding: 0,
            outline: 'none',
            cursor: 'pointer'
          }}
        />
      </ButtonBase>
      <Snackbar
        open={isCopiedOpen}
        autoHideDuration={1500}
        onClose={() => {
          setIsCopiedOpen(false)
        }}
        message={t`PhoneCopied`}
        ContentProps={{ style: { position: 'absolute', bottom: 0 } }}
      />
    </div>
  )
}

export default track({
  page: AnalyticsCategory.Error
})(Error)
